<template>
  <div id="index">
    <dv-full-screen-container class="bg">
      <dv-loading v-if="loading">Loading...</dv-loading>
      <!-- <div  v-if="!loading&&showdiv=='0'" class="host-body"> -->
      <div v-if="!loading" class="host-body">
        <!-- 第二行 -->
        <!-- <div class="d-flex jc-between px-2">
          <div class="d-flex" style="width: 100%">
            <div class="react-left bg-color-blue mr-3" style="    background-color: #110369;    height: 1.325rem;
    line-height: 1.325rem;">
              <span class="text fw-b" style="
                  font-size: 60px;
                  color: rgb(255, 193, 7);
                  float: left;
                  margin-left: 3rem;
                ">订单实时监控</span>
              <span class="text fw-b" style="
                  font-size: 36px;
                  color: rgb(255, 193, 7);
                  float: right;
                  margin-right: 2rem;
                ">{{ dateYear }} {{ dateWeek }} {{ dateDay }}</span>
            </div>
          </div>
        </div> -->

        <div class="body-box">
          <!-- 第三行数据 -->
          <div class="center-box">
            <dv-border-box-13>
              <wxordershow />
              <!-- <wxordershow v-if="!loading && showdiv == '0'" /> -->
              <!-- <ordershow v-if="!loading && showdiv == '1'" /> -->
            </dv-border-box-13>
          </div>
        </div>
      </div>
    </dv-full-screen-container>
  </div>
</template>

<script>
  import { } from '../libs/axios';
  import { formatTime } from '../utils/index.js'
  import wxordershow from "./wxordershow/wareshow";
  // import ordershow from "./wxordershow/ordershow";
  export default {
    data() {
      return {
        selectList: [], // 多选数据
        loading: true,
        showdiv: "0",
        dateDay: null,
        dateYear: null,
        dateWeek: null,
        weekday: ["周日", "周一", "周二", "周三", "周四", "周五", "周六"],
        // 添加或编辑表单对象初始化数据
        lineGuids: "",
        workShopName: "",
      };
    },
    components: {
      wxordershow,
      // ordershow,
    },
    mounted() {
      this.init();
      this.timeFn();
      this.cancelLoading();
      this.changediv1();
    },
    methods: {
      init() {
      },
      timeFn() {
        setInterval(() => {
          this.dateDay = formatTime(new Date(), 'HH: mm: ss');
          this.dateYear = formatTime(new Date(), 'yyyy-MM-dd');
          this.dateWeek = this.weekday[new Date().getDay()];
        }, 1000)
      },
      cancelLoading() {
        setTimeout(() => {
          this.loading = false;
        }, 500);
      },

      changediv1() {
        setInterval(() => {
          if (this.$store.state.divshow == "0") {
            this.showdiv = "1";
            this.$store.commit('setDivshow', "1");
          }
          else {
            this.showdiv = "0";
            this.$store.commit('setDivshow', "0");
          }

        }, 50000);
      },
    }
  };
</script>

<style lang="scss">
  @import "./groupview/index.scss";
</style>